export const todayPost = {
  id: 1,
  title: 'Today post',
  created: '2021-01-24 09:00:00',
}

export const thisWeek = {
  id: 1,
  title: 'This week post',
  created: '2021-01-23 09:00:00',
}

export const thisMonth = {
  id: 1,
  title: 'This month post',
  created: '2021-01-15 09:00:00',
}

export const data = [
  {
    id: 1,
    first_name: 'Jesse',
    last_name: 'Simmons',
    date: '2016/10/15 13:43:27',
    gender: 'Male',
  },
  {
    id: 2,
    first_name: 'John',
    last_name: 'Jacobs',
    date: '2016/12/15 06:00:53',
    gender: 'Male',
  },
  {
    id: 3,
    first_name: 'Tina',
    last_name: 'Gilbert',
    date: '2016/04/26 06:26:28',
    gender: 'Female',
  },
  {
    id: 4,
    first_name: 'Clarence',
    last_name: 'Flores',
    date: '2016/04/10 10:28:46',
    gender: 'Male',
  },
  {
    id: 5,
    first_name: 'Anne',
    last_name: 'Lee',
    date: '2016/12/06 14:38:38',
    gender: 'Female',
  },
]

export const columns = [
  {
    field: 'id',
    label: 'ID',
    width: '100',
    numeric: true,
    searchable: true,
  },
  {
    field: 'first_name',
    label: 'First Name',
    searchable: true,
  },
  {
    field: 'last_name',
    label: 'Last Name',
    searchable: true,
  },
  {
    field: 'date',
    label: 'Date',
    centered: true,
  },
  {
    field: 'gender',
    label: 'Gender',
  },
]

export const data2 = [
  {
    id: 1,
    user: {first_name: 'Jesse', last_name: 'Simmons'},
    date: '2016/10/15 13:43:27',
    gender: 'Male',
  },
  {
    id: 2,
    user: {first_name: 'John', last_name: 'Jacobs'},
    date: '2016/12/15 06:00:53',
    gender: 'Male',
  },
  {
    id: 3,
    user: {first_name: 'Tina', last_name: 'Gilbert'},
    date: '2016/04/26 06:26:28',
    gender: 'Female',
  },
  {
    id: 4,
    user: {first_name: 'Clarence', last_name: 'Flores'},
    date: '2016/04/10 10:28:46',
    gender: 'Male',
  },
  {
    id: 5,
    user: {first_name: 'Anne', last_name: 'Lee'},
    date: '2016/12/06 14:38:38',
    gender: 'Female',
  },
  {
    id: 6,
    user: {first_name: 'Sara', last_name: 'Armstrong'},
    date: '2016/09/23 18:50:04',
    gender: 'Female',
  },
  {
    id: 7,
    user: {first_name: 'Anthony', last_name: 'Webb'},
    date: '2016/08/30 23:49:38',
    gender: 'Male',
  },
  {
    id: 8,
    user: {first_name: 'Andrew', last_name: 'Greene'},
    date: '2016/11/20 14:57:47',
    gender: 'Male',
  },
  {
    id: 9,
    user: {first_name: 'Russell', last_name: 'White'},
    date: '2016/07/13 09:29:49',
    gender: 'Male',
  },
  {
    id: 10,
    user: {first_name: 'Lori', last_name: 'Hunter'},
    date: '2016/12/09 01:44:05',
    gender: 'Female',
  },
  {
    id: 11,
    user: {first_name: 'Ronald', last_name: 'Wood'},
    date: '2016/12/04 02:23:48',
    gender: 'Male',
  },
  {
    id: 12,
    user: {first_name: 'Michael', last_name: 'Harper'},
    date: '2016/07/27 13:28:15',
    gender: 'Male',
  },
  {
    id: 13,
    user: {first_name: 'George', last_name: 'Dunn'},
    date: '2017/03/07 12:26:52',
    gender: 'Male',
  },
  {
    id: 14,
    user: {first_name: 'Eric', last_name: 'Rogers'},
    date: '2016/06/07 05:41:52',
    gender: 'Male',
  },
  {
    id: 15,
    user: {first_name: 'Juan', last_name: 'Meyer'},
    date: '2017/02/01 04:56:34',
    gender: 'Male',
  },
  {
    id: 16,
    user: {first_name: 'Silvia', last_name: 'Rosa'},
    date: '2017/01/26 11:50:04',
    gender: 'Female',
  },
  {
    id: 17,
    user: {first_name: 'Lori', last_name: 'Cunningham'},
    date: '2016/05/01 10:00:56',
    gender: 'Female',
  },
  {
    id: 18,
    user: {first_name: 'Charles', last_name: 'Graham'},
    date: '2016/05/31 06:43:30',
    gender: 'Male',
  },
  {
    id: 19,
    user: {first_name: 'Henry', last_name: 'Morrison'},
    date: '2016/09/27 16:15:44',
    gender: 'Male',
  },
  {
    id: 20,
    user: {first_name: 'Albert', last_name: 'Mendoza'},
    date: '2016/08/08 05:29:24',
    gender: 'Male',
  },
  {
    id: 21,
    user: {first_name: 'Ruby', last_name: 'Snyder'},
    date: '2017/04/01 12:04:39',
    gender: 'Female',
  },
  {
    id: 22,
    user: {first_name: 'Jesse', last_name: 'Warren'},
    date: '2016/08/20 01:36:38',
    gender: 'Male',
  },
  {
    id: 23,
    user: {first_name: 'Carlos', last_name: 'Ferguson'},
    date: '2016/05/31 10:40:29',
    gender: 'Male',
  },
  {
    id: 24,
    user: {first_name: 'Melissa', last_name: 'Peters'},
    date: '2016/07/23 00:41:54',
    gender: 'Female',
  },
  {
    id: 25,
    user: {first_name: 'Arthur', last_name: 'Garza'},
    date: '2017/03/11 14:11:37',
    gender: 'Male',
  },
  {
    id: 26,
    user: {first_name: 'Joe', last_name: 'Berry'},
    date: '2016/07/09 15:16:56',
    gender: 'Male',
  },
  {
    id: 27,
    user: {first_name: 'Joseph', last_name: 'Bishop'},
    date: '2016/10/04 19:44:54',
    gender: 'Male',
  },
  {
    id: 28,
    user: {first_name: 'Sarah', last_name: 'Harper'},
    date: '2016/09/23 05:09:11',
    gender: 'Female',
  },
  {
    id: 29,
    user: {first_name: 'Christopher', last_name: 'Fuller'},
    date: '2016/04/12 00:05:35',
    gender: 'Male',
  },
  {
    id: 30,
    user: {first_name: 'Alan', last_name: 'Mendoza'},
    date: '2016/04/22 10:48:02',
    gender: 'Male',
  },
  {
    id: 31,
    user: {first_name: 'James', last_name: 'Davis'},
    date: '2017/01/16 15:17:03',
    gender: 'Male',
  },
  {
    id: 32,
    user: {first_name: 'Scott', last_name: 'Welch'},
    date: '2016/10/04 23:31:51',
    gender: 'Male',
  },
  {
    id: 33,
    user: {first_name: 'Mildred', last_name: 'Myers'},
    date: '2016/11/23 13:46:18',
    gender: 'Female',
  },
  {
    id: 34,
    user: {first_name: 'Victor', last_name: 'Martinez'},
    date: '2016/04/06 17:05:07',
    gender: 'Male',
  },
  {
    id: 35,
    user: {first_name: 'Susan', last_name: 'Medina'},
    date: '2016/12/09 10:33:37',
    gender: 'Female',
  },
  {
    id: 36,
    user: {first_name: 'Judy', last_name: 'Long'},
    date: '2016/07/26 16:19:04',
    gender: 'Female',
  },
  {
    id: 37,
    user: {first_name: 'Joan', last_name: 'Myers'},
    date: '2016/09/22 04:55:54',
    gender: 'Female',
  },
  {
    id: 38,
    user: {first_name: 'Rachel', last_name: 'Gonzales'},
    date: '2016/07/15 13:56:38',
    gender: 'Female',
  },
  {
    id: 39,
    user: {first_name: 'Roger', last_name: 'Hunt'},
    date: '2016/08/14 10:43:11',
    gender: 'Male',
  },
  {
    id: 40,
    user: {first_name: 'Dorothy', last_name: 'Howard'},
    date: '2016/06/19 05:34:49',
    gender: 'Female',
  },
  {
    id: 41,
    user: {first_name: 'Eugene', last_name: 'Lynch'},
    date: '2016/12/24 08:19:24',
    gender: 'Male',
  },
  {
    id: 42,
    user: {first_name: 'Kathy', last_name: 'Webb'},
    date: '2017/04/01 21:09:05',
    gender: 'Female',
  },
  {
    id: 43,
    user: {first_name: 'Antonio', last_name: 'White'},
    date: '2017/02/10 06:51:20',
    gender: 'Male',
  },
  {
    id: 44,
    user: {first_name: 'Louis', last_name: 'Spencer'},
    date: '2016/10/08 02:20:22',
    gender: 'Male',
  },
  {
    id: 45,
    user: {first_name: 'Andrea', last_name: 'Marshall'},
    date: '2016/09/04 10:59:57',
    gender: 'Female',
  },
  {
    id: 46,
    user: {first_name: 'Eugene', last_name: 'Sims'},
    date: '2017/03/15 06:39:48',
    gender: 'Male',
  },
  {
    id: 47,
    user: {first_name: 'Mildred', last_name: 'Gibson'},
    date: '2016/04/18 06:43:54',
    gender: 'Female',
  },
  {
    id: 48,
    user: {first_name: 'Joan', last_name: 'Arnold'},
    date: '2016/12/16 04:52:23',
    gender: 'Female',
  },
  {
    id: 49,
    user: {first_name: 'Jesse', last_name: 'Schmidt'},
    date: '2016/06/11 02:44:33',
    gender: 'Male',
  },
  {
    id: 50,
    user: {first_name: 'David', last_name: 'Frazier'},
    date: '2017/02/15 21:46:30',
    gender: 'Male',
  },
  {
    id: 51,
    user: {first_name: 'Nicholas', last_name: 'Howell'},
    date: '2016/11/01 15:08:31',
    gender: 'Male',
  },
  {
    id: 52,
    user: {first_name: 'Douglas', last_name: 'Chapman'},
    date: '2017/02/08 03:33:24',
    gender: 'Male',
  },
  {
    id: 53,
    user: {first_name: 'Bruce', last_name: 'Simmons'},
    date: '2016/07/14 12:11:17',
    gender: 'Male',
  },
  {
    id: 54,
    user: {first_name: 'Antonio', last_name: 'George'},
    date: '2016/11/07 19:12:55',
    gender: 'Male',
  },
  {
    id: 55,
    user: {first_name: 'Chris', last_name: 'Marshall'},
    date: '2016/07/03 12:11:45',
    gender: 'Male',
  },
  {
    id: 56,
    user: {first_name: 'Ashley', last_name: 'Hudson'},
    date: '2016/10/14 21:08:05',
    gender: 'Female',
  },
  {
    id: 57,
    user: {first_name: 'Alan', last_name: 'Edwards'},
    date: '2017/03/22 21:10:25',
    gender: 'Male',
  },
  {
    id: 58,
    user: {first_name: 'George', last_name: 'Clark'},
    date: '2016/04/28 03:15:05',
    gender: 'Male',
  },
  {
    id: 59,
    user: {first_name: 'Frank', last_name: 'Porter'},
    date: '2016/09/08 00:48:14',
    gender: 'Male',
  },
  {
    id: 60,
    user: {first_name: 'Christopher', last_name: 'Palmer'},
    date: '2016/05/24 08:58:12',
    gender: 'Male',
  },
]

export const exampleColumns = ['name', 'code', 'uri']

export const exampleData = [
  {
    code: 'ZW',
    name: 'Zimbabwe',
    created_at: '2015-04-24T01:46:50.459583',
    updated_at: '2015-04-24T01:46:50.459593',
    uri: 'http://api.lobbyfacts.eu/api/1/country/245',
    id: 245,
  },
  {
    code: 'ZM',
    name: 'Zambia',
    created_at: '2015-04-24T01:46:50.457459',
    updated_at: '2015-04-24T01:46:50.457468',
    uri: 'http://api.lobbyfacts.eu/api/1/country/244',
    id: 244,
  },
  {
    code: 'YE',
    name: 'Yemen',
    created_at: '2015-04-24T01:46:50.454731',
    updated_at: '2015-04-24T01:46:50.454741',
    uri: 'http://api.lobbyfacts.eu/api/1/country/243',
    id: 243,
  },
  {
    code: 'EH',
    name: 'Western Sahara',
    created_at: '2015-04-24T01:46:50.452002',
    updated_at: '2015-04-24T01:46:50.452011',
    uri: 'http://api.lobbyfacts.eu/api/1/country/242',
    id: 242,
  },
  {
    code: 'WF',
    name: 'Wallis & Futuna',
    created_at: '2015-04-24T01:46:50.449346',
    updated_at: '2015-04-24T01:46:50.449355',
    uri: 'http://api.lobbyfacts.eu/api/1/country/241',
    id: 241,
  },
  {
    code: 'VN',
    name: 'Vietnam',
    created_at: '2015-04-24T01:46:50.446644',
    updated_at: '2015-04-24T01:46:50.446652',
    uri: 'http://api.lobbyfacts.eu/api/1/country/240',
    id: 240,
  },
  {
    code: 'VE',
    name: 'Venezuela',
    created_at: '2015-04-24T01:46:50.444031',
    updated_at: '2015-04-24T01:46:50.444040',
    uri: 'http://api.lobbyfacts.eu/api/1/country/239',
    id: 239,
  },
  {
    code: 'VU',
    name: 'Vanuatu',
    created_at: '2015-04-24T01:46:50.441423',
    updated_at: '2015-04-24T01:46:50.441433',
    uri: 'http://api.lobbyfacts.eu/api/1/country/238',
    id: 238,
  },
  {
    code: 'UZ',
    name: 'Uzbekistan',
    created_at: '2015-04-24T01:46:50.438748',
    updated_at: '2015-04-24T01:46:50.438757',
    uri: 'http://api.lobbyfacts.eu/api/1/country/237',
    id: 237,
  },
  {
    code: 'UY',
    name: 'Uruguay',
    created_at: '2015-04-24T01:46:50.435761',
    updated_at: '2015-04-24T01:46:50.435770',
    uri: 'http://api.lobbyfacts.eu/api/1/country/236',
    id: 236,
  },
  {
    code: 'VI',
    name: 'United States Virgin Islands',
    created_at: '2015-04-24T01:46:50.433229',
    updated_at: '2015-04-24T01:46:50.433238',
    uri: 'http://api.lobbyfacts.eu/api/1/country/235',
    id: 235,
  },
  {
    code: 'US',
    name: 'United States',
    created_at: '2015-04-24T01:46:50.430335',
    updated_at: '2015-04-24T01:46:50.430340',
    uri: 'http://api.lobbyfacts.eu/api/1/country/234',
    id: 234,
  },
  {
    code: 'GB',
    name: 'United Kingdom',
    created_at: '2015-04-24T01:46:50.427956',
    updated_at: '2015-04-24T01:46:50.427961',
    uri: 'http://api.lobbyfacts.eu/api/1/country/233',
    id: 233,
  },
  {
    code: 'AE',
    name: 'United Arab Emirates',
    created_at: '2015-04-24T01:46:50.425383',
    updated_at: '2015-04-24T01:46:50.425392',
    uri: 'http://api.lobbyfacts.eu/api/1/country/232',
    id: 232,
  },
  {
    code: 'UA',
    name: 'Ukraine',
    created_at: '2015-04-24T01:46:50.422970',
    updated_at: '2015-04-24T01:46:50.422980',
    uri: 'http://api.lobbyfacts.eu/api/1/country/231',
    id: 231,
  },
  {
    code: 'UG',
    name: 'Uganda',
    created_at: '2015-04-24T01:46:50.419963',
    updated_at: '2015-04-24T01:46:50.419968',
    uri: 'http://api.lobbyfacts.eu/api/1/country/230',
    id: 230,
  },
  {
    code: 'TV',
    name: 'Tuvalu',
    created_at: '2015-04-24T01:46:50.417896',
    updated_at: '2015-04-24T01:46:50.417906',
    uri: 'http://api.lobbyfacts.eu/api/1/country/229',
    id: 229,
  },
  {
    code: 'TC',
    name: 'Turks & Caicos Islands',
    created_at: '2015-04-24T01:46:50.414854',
    updated_at: '2015-04-24T01:46:50.414868',
    uri: 'http://api.lobbyfacts.eu/api/1/country/228',
    id: 228,
  },
  {
    code: 'TM',
    name: 'Turkmenistan',
    created_at: '2015-04-24T01:46:50.412601',
    updated_at: '2015-04-24T01:46:50.412605',
    uri: 'http://api.lobbyfacts.eu/api/1/country/227',
    id: 227,
  },
  {
    code: 'TR',
    name: 'Turkey',
    created_at: '2015-04-24T01:46:50.411105',
    updated_at: '2015-04-24T01:46:50.411110',
    uri: 'http://api.lobbyfacts.eu/api/1/country/226',
    id: 226,
  },
  {
    code: 'TN',
    name: 'Tunisia',
    created_at: '2015-04-24T01:46:50.409535',
    updated_at: '2015-04-24T01:46:50.409539',
    uri: 'http://api.lobbyfacts.eu/api/1/country/225',
    id: 225,
  },
  {
    code: 'TT',
    name: 'Trinidad & Tobago',
    created_at: '2015-04-24T01:46:50.408030',
    updated_at: '2015-04-24T01:46:50.408034',
    uri: 'http://api.lobbyfacts.eu/api/1/country/224',
    id: 224,
  },
  {
    code: 'TO',
    name: 'Tonga',
    created_at: '2015-04-24T01:46:50.406306',
    updated_at: '2015-04-24T01:46:50.406311',
    uri: 'http://api.lobbyfacts.eu/api/1/country/223',
    id: 223,
  },
  {
    code: 'TK',
    name: 'Tokelau',
    created_at: '2015-04-24T01:46:50.404794',
    updated_at: '2015-04-24T01:46:50.404799',
    uri: 'http://api.lobbyfacts.eu/api/1/country/222',
    id: 222,
  },
  {
    code: 'TG',
    name: 'Togo',
    created_at: '2015-04-24T01:46:50.403306',
    updated_at: '2015-04-24T01:46:50.403310',
    uri: 'http://api.lobbyfacts.eu/api/1/country/221',
    id: 221,
  },
  {
    code: 'TH',
    name: 'Thailand',
    created_at: '2015-04-24T01:46:50.400840',
    updated_at: '2015-04-24T01:46:50.400849',
    uri: 'http://api.lobbyfacts.eu/api/1/country/220',
    id: 220,
  },
  {
    code: 'TZ',
    name: 'Tanzania',
    created_at: '2015-04-24T01:46:50.397846',
    updated_at: '2015-04-24T01:46:50.397855',
    uri: 'http://api.lobbyfacts.eu/api/1/country/219',
    id: 219,
  },
  {
    code: 'TJ',
    name: 'Tajikistan',
    created_at: '2015-04-24T01:46:50.394924',
    updated_at: '2015-04-24T01:46:50.394933',
    uri: 'http://api.lobbyfacts.eu/api/1/country/218',
    id: 218,
  },
  {
    code: 'TW',
    name: 'Taiwan',
    created_at: '2015-04-24T01:46:50.391969',
    updated_at: '2015-04-24T01:46:50.391978',
    uri: 'http://api.lobbyfacts.eu/api/1/country/217',
    id: 217,
  },
  {
    code: 'SY',
    name: 'Syria',
    created_at: '2015-04-24T01:46:50.389120',
    updated_at: '2015-04-24T01:46:50.389124',
    uri: 'http://api.lobbyfacts.eu/api/1/country/216',
    id: 216,
  },
  {
    code: 'CH',
    name: 'Switzerland',
    created_at: '2015-04-24T01:46:50.386939',
    updated_at: '2015-04-24T01:46:50.386943',
    uri: 'http://api.lobbyfacts.eu/api/1/country/215',
    id: 215,
  },
  {
    code: 'SE',
    name: 'Sweden',
    created_at: '2015-04-24T01:46:50.385345',
    updated_at: '2015-04-24T01:46:50.385349',
    uri: 'http://api.lobbyfacts.eu/api/1/country/214',
    id: 214,
  },
  {
    code: 'SZ',
    name: 'Swaziland',
    created_at: '2015-04-24T01:46:50.383834',
    updated_at: '2015-04-24T01:46:50.383838',
    uri: 'http://api.lobbyfacts.eu/api/1/country/213',
    id: 213,
  },
  {
    code: 'SR',
    name: 'Suriname',
    created_at: '2015-04-24T01:46:50.382073',
    updated_at: '2015-04-24T01:46:50.382078',
    uri: 'http://api.lobbyfacts.eu/api/1/country/212',
    id: 212,
  },
  {
    code: 'SD',
    name: 'Sudan',
    created_at: '2015-04-24T01:46:50.380114',
    updated_at: '2015-04-24T01:46:50.380119',
    uri: 'http://api.lobbyfacts.eu/api/1/country/211',
    id: 211,
  },
  {
    code: 'LK',
    name: 'Sri Lanka',
    created_at: '2015-04-24T01:46:50.378189',
    updated_at: '2015-04-24T01:46:50.378195',
    uri: 'http://api.lobbyfacts.eu/api/1/country/210',
    id: 210,
  },
  {
    code: 'ES',
    name: 'Spain',
    created_at: '2015-04-24T01:46:50.376105',
    updated_at: '2015-04-24T01:46:50.376109',
    uri: 'http://api.lobbyfacts.eu/api/1/country/209',
    id: 209,
  },
  {
    code: 'SS',
    name: 'South Sudan',
    created_at: '2015-04-24T01:46:50.373942',
    updated_at: '2015-04-24T01:46:50.373946',
    uri: 'http://api.lobbyfacts.eu/api/1/country/208',
    id: 208,
  },
  {
    code: 'KR',
    name: 'South Korea',
    created_at: '2015-04-24T01:46:50.371790',
    updated_at: '2015-04-24T01:46:50.371794',
    uri: 'http://api.lobbyfacts.eu/api/1/country/207',
    id: 207,
  },
  {
    code: 'GS',
    name: 'South Georgia & The South Sandwish Islands',
    created_at: '2015-04-24T01:46:50.369460',
    updated_at: '2015-04-24T01:46:50.369465',
    uri: 'http://api.lobbyfacts.eu/api/1/country/206',
    id: 206,
  },
  {
    code: 'ZA',
    name: 'South Africa',
    created_at: '2015-04-24T01:46:50.367247',
    updated_at: '2015-04-24T01:46:50.367252',
    uri: 'http://api.lobbyfacts.eu/api/1/country/205',
    id: 205,
  },
  {
    code: 'SO',
    name: 'Somaliland',
    created_at: '2015-04-24T01:46:50.362905',
    updated_at: '2016-09-18T18:34:35.724427',
    uri: 'http://api.lobbyfacts.eu/api/1/country/204',
    id: 204,
  },
  {
    code: 'SB',
    name: 'Solomon Islands',
    created_at: '2015-04-24T01:46:50.360631',
    updated_at: '2015-04-24T01:46:50.360635',
    uri: 'http://api.lobbyfacts.eu/api/1/country/203',
    id: 203,
  },
  {
    code: 'SI',
    name: 'Slovenia',
    created_at: '2015-04-24T01:46:50.358394',
    updated_at: '2015-04-24T01:46:50.358399',
    uri: 'http://api.lobbyfacts.eu/api/1/country/202',
    id: 202,
  },
  {
    code: 'SK',
    name: 'Slovakia',
    created_at: '2015-04-24T01:46:50.356154',
    updated_at: '2015-04-24T01:46:50.356158',
    uri: 'http://api.lobbyfacts.eu/api/1/country/201',
    id: 201,
  },
  {
    code: 'SX',
    name: 'Sint Maarten',
    created_at: '2015-04-24T01:46:50.353807',
    updated_at: '2015-04-24T01:46:50.353811',
    uri: 'http://api.lobbyfacts.eu/api/1/country/200',
    id: 200,
  },
  {
    code: 'SG',
    name: 'Singapore',
    created_at: '2015-04-24T01:46:50.349354',
    updated_at: '2015-04-24T01:46:50.349358',
    uri: 'http://api.lobbyfacts.eu/api/1/country/199',
    id: 199,
  },
  {
    code: 'SL',
    name: 'Sierra Leone',
    created_at: '2015-04-24T01:46:50.347186',
    updated_at: '2015-04-24T01:46:50.347190',
    uri: 'http://api.lobbyfacts.eu/api/1/country/198',
    id: 198,
  },
  {
    code: 'SC',
    name: 'Seychelles',
    created_at: '2015-04-24T01:46:50.344980',
    updated_at: '2015-04-24T01:46:50.344984',
    uri: 'http://api.lobbyfacts.eu/api/1/country/197',
    id: 197,
  },
  {
    code: 'RS',
    name: 'Serbia',
    created_at: '2015-04-24T01:46:50.342496',
    updated_at: '2015-04-24T01:46:50.342501',
    uri: 'http://api.lobbyfacts.eu/api/1/country/196',
    id: 196,
  },
]

export const exampleOptions = {
  headings: {
    name: 'Country Name',
    code: 'Country Code',
    uri: 'View Record',
  },
  editableColumns: ['name'],
  sortable: ['name', 'code'],
  filterable: ['name', 'code'],
}

export const datasets = [
  {
    label: '# of Votes',
    data: [12, 19, 3, 5, 2, 3],
    backgroundColor: [
      'rgba(255, 99, 132, 0.2)',
      'rgba(54, 162, 235, 0.2)',
      'rgba(255, 206, 86, 0.2)',
      'rgba(75, 192, 192, 0.2)',
      'rgba(153, 102, 255, 0.2)',
      'rgba(255, 159, 64, 0.2)',
    ],
    borderColor: [
      'rgba(255, 99, 132, 1)',
      'rgba(54, 162, 235, 1)',
      'rgba(255, 206, 86, 1)',
      'rgba(75, 192, 192, 1)',
      'rgba(153, 102, 255, 1)',
      'rgba(255, 159, 64, 1)',
    ],
    borderWidth: 1,
  },
]

export const labels = ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange']

export const tableData = [
  {id: 1, name: 'John', age: '20'},
  {id: 2, name: 'Jane', age: '24'},
  {id: 3, name: 'Susan', age: '16'},
  {id: 4, name: 'Chris', age: '55'},
  {id: 5, name: 'Dan', age: '40'},
]

export const columns2 = ['id', 'name', 'age']

export const detailData = [
  {
    name: 'Board Games',
    sold: 131,
    available: 301,
    items: [
      {
        name: 'Monopoly',
        sold: 57,
        available: 100,
      },
      {
        name: 'Scrabble',
        sold: 23,
        available: 84,
      },
      {
        name: 'Chess',
        sold: 37,
        available: 61,
      },
      {
        name: 'Battleships',
        sold: 14,
        available: 56,
      },
    ],
  },
  {
    name: 'Jigsaws & Puzzles',
    sold: 88,
    available: 167,
    items: [
      {
        name: 'World Map',
        sold: 31,
        available: 38,
      },
      {
        name: 'London',
        sold: 23,
        available: 29,
      },
      {
        name: 'Sharks',
        sold: 20,
        available: 44,
      },
      {
        name: 'Disney',
        sold: 14,
        available: 56,
      },
    ],
  },
  {
    name: 'Books',
    sold: 434,
    available: 721,
    items: [
      {
        name: 'Hamlet',
        sold: 101,
        available: 187,
      },
      {
        name: 'The Lord Of The Rings',
        sold: 85,
        available: 156,
      },
      {
        name: 'To Kill a Mockingbird',
        sold: 78,
        available: 131,
      },
      {
        name: 'Catch-22',
        sold: 73,
        available: 98,
      },
      {
        name: 'Frankenstein',
        sold: 51,
        available: 81,
      },
      {
        name: "Alice's Adventures In Wonderland",
        sold: 46,
        available: 68,
      },
    ],
  },
]

export const dataDetails = [
  {
    name: 'Board Games',
    sold: 131,
    available: 301,
    items: [
      {
        name: 'Monopoly',
        sold: 57,
        available: 100,
      },
      {
        name: 'Scrabble',
        sold: 23,
        available: 84,
      },
      {
        name: 'Chess',
        sold: 37,
        available: 61,
      },
      {
        name: 'Battleships',
        sold: 14,
        available: 56,
      },
    ],
  },
  {
    name: 'Jigsaws & Puzzles',
    sold: 88,
    available: 167,
    items: [
      {
        name: 'World Map',
        sold: 31,
        available: 38,
      },
      {
        name: 'London',
        sold: 23,
        available: 29,
      },
      {
        name: 'Sharks',
        sold: 20,
        available: 44,
      },
      {
        name: 'Disney',
        sold: 14,
        available: 56,
      },
    ],
  },
  {
    name: 'Books',
    sold: 434,
    available: 721,
    items: [
      {
        name: 'Hamlet',
        sold: 101,
        available: 187,
      },
      {
        name: 'The Lord Of The Rings',
        sold: 85,
        available: 156,
      },
      {
        name: 'To Kill a Mockingbird',
        sold: 78,
        available: 131,
      },
      {
        name: 'Catch-22',
        sold: 73,
        available: 98,
      },
      {
        name: 'Frankenstein',
        sold: 51,
        available: 81,
      },
      {
        name: "Alice's Adventures In Wonderland",
        sold: 46,
        available: 68,
      },
    ],
  },
]

export const option = {
  title: {
    text: 'Example',
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross',
      label: {
        backgroundColor: '#6a7985',
      },
    },
  },
  legend: {
    data: ['邮件营销', '联盟广告', '视频广告', '直接访问', '搜索引擎'],
  },
  toolbox: {
    feature: {
      saveAsImage: {},
    },
  },
  grid: {
    left: '3%',
    right: '4%',
    bottom: '3%',
    containLabel: true,
  },
  xAxis: [
    {
      type: 'category',
      boundaryGap: false,
      data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
    },
  ],
  yAxis: [
    {
      type: 'value',
    },
  ],
  series: [
    {
      name: '邮件营销',
      type: 'line',
      stack: '总量',
      areaStyle: {},
      data: [120, 132, 101, 134, 90, 230, 210],
    },
    {
      name: '联盟广告',
      type: 'line',
      stack: '总量',
      areaStyle: {},
      data: [220, 182, 191, 234, 290, 330, 310],
    },
    {
      name: '视频广告',
      type: 'line',
      stack: '总量',
      areaStyle: {},
      data: [150, 232, 201, 154, 190, 330, 410],
    },
    {
      name: '直接访问',
      type: 'line',
      stack: '总量',
      areaStyle: {},
      data: [320, 332, 301, 334, 390, 330, 320],
    },
    {
      name: '搜索引擎',
      type: 'line',
      stack: '总量',
      label: {
        show: true,
        position: 'top',
      },
      areaStyle: {},
      data: [820, 932, 901, 934, 1290, 1330, 1320],
    },
  ],
}

export const chartData = [
  ['Jan', [4, 6]],
  ['Feb', 2],
  ['Mar', 10],
  ['Apr', 5],
  ['May', 3],
]

export const menus = [
  {value: 'mr', text: 'Mr.'},
  {value: 'mrs', text: 'Mrs.'},
]
