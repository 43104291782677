<script lang="ts">
import {
  defineComponent,
  reactive,
  ref,
  computed,
  onMounted,
} from '@vue/composition-api'
import {useStore} from '@/composables/useStore'
import {filterPosts} from '@/helpers/post-utils'
import moment from 'moment'
import {mapState, mapMutations} from 'vuex'

import {
  serialize,
  deserialize,
  serializeMoment,
  deserializeMoment,
} from '@/helpers/date-time-serializers'

import {useMouse, usePreferredDark, useLocalStorage} from '@vueuse/core'
import {useSound} from '@vueuse/sound'
import Button from '@/assets/sound/button.wav'

import {
  columns,
  data,
  data2,
  datasets,
  exampleData,
  exampleColumns,
  exampleOptions,
  columns2,
  tableData,
  detailData,
  dataDetails,
  labels,
  option,
  chartData,
  menus,
} from '@/__mocks__'

export default defineComponent({
  name: 'DashboardView',

  setup() {
    // console.log({
    //   sentryDsn: process.env.MIX_SENTRY_DSN_PUBLIC,
    //   awsKey: process.env.MIX_AWS_S3_ACCESS_KEY,
    // })
    const periods = ['today', 'this week', 'this month']
    const selectedPeriod = ref('this week')
    const people = reactive([])
    const showText = ref(false)
    const modalOpen = ref(false)
    const store = useStore
    const selectedUserType = 'Executive'
    const userTypes = ref([
      'Executive',
      'Staff',
      'Certified Advocate',
      'Advocate in Training',
    ])

    const dateMoment = ref(moment('2019-02-02', 'YYYY-MM-DD'))

    // tracks mouse position
    const {x, y} = useMouse()
    const {play} = useSound(Button)
    // is user prefers dark theme
    const isDark = usePreferredDark()

    // persist state in localStorage
    const storage = useLocalStorage('my-storage', {
      name: 'Apple',
      color: 'red',
    })

    onMounted(async () => {
      if (!store.getState().posts.loaded) {
        await store.fetchPosts()
      }
    })

    const allPosts = computed(() =>
      store.getState().posts.ids.reduce((acc, id) => {
        const post = store.getState().posts.all[id]
        return acc.concat(post)
      }, []),
    )

    const posts = computed(() =>
      filterPosts(allPosts.value, selectedPeriod.value),
    )

    // console.log({store, posts, allPosts})

    const setPeriod = period => (selectedPeriod.value = period)

    return {
      x,
      y,
      isDark,
      play,
      storage,
      store,
      showText,
      modalOpen,
      periods,
      setPeriod,
      selectedPeriod,
      posts,
      allPosts,
      serialize,
      deserialize,
      selectedUserType,
      dateMoment,
      serializeMoment,
      userTypes,
      deserializeMoment,
      people,
      username: ref(''),
    }
  },
  components: {},
  data() {
    return {
      data,
      data2,
      columns,
      exampleColumns,
      exampleData,
      exampleOptions,
      labels,
      datasets,
      columns2,
      tableData,
      detailData,
      dataDetails,
      option,
      chartData,
      menus,
      activeTab: 2,
      activeFramework: 2,
      activeLanguage: 2,
      dropFiles: [],
      count: 1,
      columnsVisible: {
        name: {title: 'Name', display: true},
        sold: {title: 'Stock Sold', display: true},
        available: {title: 'Stock Available', display: true},
        cleared: {title: 'Stock Cleared', display: true},
      },
      // selected: data[1],
      // checkedRows: [data[1], data[3]],
      checkboxPosition: 'left',
      currentPage: 1,
      currentMenu: {value: 'mr', text: 'Mr.'},
      defaultSortDirection: 'asc',
      howMessage: true,
      isBordered: false,
      isCardModalActive: false,
      isEmpty: false,
      isFocusable: false,
      isHoverable: false,
      isImageModalActive: false,
      isLoading: false,
      isNarrowed: false,
      isOpen: 0,
      isPaginated: true,
      isPaginationSimple: false,
      isStriped: false,
      messages: {},
      name: '',
      paginationPosition: 'bottom',
      perPage: 5,
      showDefaultDetail: true,
      showDetailIcon: true,
      showModal: false,
      showSidebar: false,
      overlay: true,
      fullheight: true,
      fullwidth: false,
      right: false,
      sortIcon: 'arrow-up',
      sortIconSize: 'small',
      stickyHeaders: true,

      pathname: 'JavaScript',
      frameworks: [
        {
          path: 'javascript',
          name: 'JavaScript is the name',
        },
      ],
    }
  },
  computed: {
    ...mapState('menu', ['isMenuOpen']),
    framework() {
      return this.frameworks.find(framework =>
        // window.location.pathname.toLowerCase().includes(framework.path),
        this.pathname.toLowerCase().includes(framework.path),
      )
    },
  },
  mounted() {
    setTimeout(() => {
      this.count++
    }, 3 * 1000)
  },
  methods: {
    ...mapMutations('menu', {
      toggleMenu: 'TOGGLE_MENU', // map `this.add()` to `this.$store.commit('increment')`
    }),
    onDblclick() {
      alert('The listener is triggered')
    },
    increment() {
      this.count += 1
    },
    toggle(row) {
      this.$refs.table.toggleDetails(row)
    },
    clickMe() {
      alert('Clicked!')
    },
    deleteDropFile(index) {
      this.dropFiles.splice(index, 1)
    },
    fetchUsers() {
      this.$store.dispatch('getUsers')
    },

    updateUsers() {
      this.$store.dispatch('updateUsers', this.$store.state.users)
    },
  },
  page() {
    return {
      title: 'Welcome',
    }
  },
})
</script>

<template>
  <main class="flex-1 w-full h-screen bg-gray-100">
    <!-- <PageHeading> Dashboard </PageHeading> -->
    <BaseBreadcrumb />

    <div class="space-y-1 lg:mt-8 lg:space-y-8 lg:px-8">
      <!-- Modals -->
      <section class="p-8 bg-opacity-50 shadow bg-primary-500 lg:rounded-lg">
        <div class="flex items-center space-x-8 buttons">
          <NotificationsButton />

          <OButton @click="showSidebar = true">Show Sidebar</OButton>

          <button @click="toggleMenu">Toggle</button>

          <PrivacyPolicyButton />

          <OButton
            size="medium"
            variant="primary"
            @click="isImageModalActive = true"
          >
            Open modal
          </OButton>

          <OButton
            size="medium"
            variant="primary"
            @click="isCardModalActive = true"
          >
            Open modal (clip scroll)
          </OButton>

          <div>
            <OButton @click="showModal = true">Show Modal</OButton>

            <Portal to="overlays" v-if="showModal">
              <BaseModal v-if="showModal">
                <h1>Hi Vue School</h1>
                <button @click="showModal = false">Close</button>
              </BaseModal>
            </Portal>
          </div>

          <button
            class="text-blue-500 border-b border-blue-500 border-dotted hover:border-solid"
            @click="modalOpen = true"
          >
            Privacy Policy
            <Portal v-if="modalOpen" to="overlays">
              <PrivacyPolicyModal
                :show="modalOpen"
                @close="modalOpen = false"
              />
            </Portal>
          </button>
        </div>

        <OModal v-model="isImageModalActive">
          <img
            src="/assets/img/book_art/a-baptist-preachers-buddhist-teacher.jpg"
          />
        </OModal>

        <OModal v-model="isCardModalActive" :width="640" scroll="clip">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
            sodales leo nec convallis rutrum. Vivamus pharetra molestie arcu at
            dictum. Nulla faucibus leo eget enim egestas, in tempus justo
            venenatis. Duis dictum suscipit erat, a dapibus eros lobortis ac.
            Praesent tempor rhoncus convallis. Nullam in ipsum convallis, rutrum
            elit eget, dictum ipsum. Nunc sagittis aliquet massa. Etiam lacus
            sapien, eleifend non eros quis, finibus ornare nisl. Ut laoreet sit
            amet lacus non dignissim. Sed convallis mattis enim, sed interdum
            risus molestie ut. Praesent vel ex hendrerit, cursus lectus a,
            blandit felis. Nam luctus orci nec varius commodo.
          </p>
          <p>
            Sed vulputate metus purus, ut egestas erat congue et. Donec tellus
            orci, malesuada eget dolor sed, pellentesque bibendum nunc. In eu
            egestas diam. Integer sed congue massa. Sed a urna quam. Morbi
            vulputate dolor eleifend ligula lobortis venenatis. Aenean
            pellentesque risus sit amet faucibus molestie. Aliquam eu lorem
            aliquet, aliquam nulla in, vestibulum lorem. Donec mollis mi at
            sollicitudin tristique. Nullam id nibh pulvinar, dignissim nisl id,
            gravida risus. Nulla arcu elit, scelerisque in sollicitudin et,
            laoreet et metus. Aenean placerat turpis nec tincidunt tempus.
          </p>
        </OModal>
      </section>

      <Charts />

      <!-- Meet the Team -->
      <section class="p-8 bg-white shadow lg:rounded-lg">
        <h1>Meet the team</h1>

        <div class="switcher">
          <article v-for="person in people" :key="person" class="duotone-card">
            <div class="duotone-card__inner">
              <div class="duotone-card__content">
                <h2 class="duotone-card__heading">{{ person.name }}</h2>
                <p class="duotone-card__summary">{{ person.title }}</p>
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  width="1em"
                  height="1em"
                  viewBox="0 0 24 24"
                  class="duotone-card__icon"
                >
                  <path
                    d="M9.707 18.707l6-6c0.391-0.391 0.391-1.024 0-1.414l-6-6c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414l5.293 5.293-5.293 5.293c-0.391 0.391-0.391 1.024 0 1.414s1.024 0.391 1.414 0z"
                  />
                </svg>
              </div>
              <button type="button" class="cover-button">
                <span class="visually-hidden"
                  >{{ person.firstName }}’s profile</span
                >
              </button>

              <div class="duotone-card__media">
                <img
                  :src="person.avatarUrl"
                  width="300"
                  alt=""
                  aria-hidden="true"
                  loading="lazy"
                />
              </div>
            </div>
          </article>
        </div>
      </section>

      <!-- Form Validation -->
      <section class="p-8 bg-white shadow lg:rounded-lg">
        <FormValidation />
      </section>

      <!-- Counter -->
      <section class="p-8 bg-white shadow lg:rounded-lg">
        <Hello @greet="increment" />
        Count: {{ count }}
      </section>

      <!-- 16x9 Figure -->
      <section class="p-8 bg-opacity-50 shadow bg-primary-500 lg:rounded-lg">
        <figure class="aspect-w-16 aspect-h-9">
          <iframe
            title="vimeo-player"
            src="https://player.vimeo.com/video/330438293"
            width="640"
            height="360"
            frameborder="0"
            allowfullscreen
          />
        </figure>
      </section>

      <!-- Posts -->
      <section class="p-8 bg-opacity-50 shadow bg-primary-500 lg:rounded-lg">
        <OButton
          v-for="period in periods"
          :key="period"
          data-testid="period"
          :variant="{primary: period === selectedPeriod}"
          @click="setPeriod(period)"
        >
          {{ period }}
        </OButton>

        {{ posts }}
        {{ allPosts }}

        <div
          v-for="post in posts"
          :key="post.id"
          data-testid="post"
          class="panel-block"
        >
          <div>
            <a>{{ post.title }}</a>
            <div>{{ post.created.format('Do MMM') }}</div>
          </div>
        </div>
      </section>

      <!-- Tabs -->
      <section class="p-8 space-y-4 bg-white shadow lg:rounded-lg">
        <h2>Tabs</h2>

        <OTabs
          v-model="activeTab"
          :expanded="false"
          :animated="false"
          variant="primary"
        >
          <OTabItem>
            <template slot="header">
              <OIcon pack="fas" icon="info-circle"></OIcon>
              <span> Issues 3 </span>
            </template>

            <div>Content</div>
          </OTabItem>
          <OTabItem>
            <template slot="header">
              <OIcon pack="fas" icon="retweet"></OIcon>
              <span> Pull Requests {{ count }} </span>
            </template>
            <div>Pull Requests</div>
          </OTabItem>
        </OTabs>

        <OTabs
          v-model="activeLanguage"
          :expanded="false"
          :animated="false"
          variant="primary"
        >
          <OTabItem>
            <template slot="header">
              <OIcon pack="fas" icon="code" />
              <span> HTML/CSS </span>
            </template>
            <div>HTML/CSS</div>
          </OTabItem>

          <OTabItem>
            <template slot="header">
              <OIcon pack="fab" icon="js-square" />
              <span> JavaScript </span>
            </template>
            <div>JavaScript</div>
          </OTabItem>

          <OTabItem>
            <template slot="header">
              <OIcon pack="fab" icon="php" />
              <span> PHP </span>
            </template>
            <div>PHP</div>
          </OTabItem>

          <OTabItem>
            <template slot="header">
              <OIcon pack="fas" icon="database" />
              <span> SQL </span>
            </template>
            <div>SQL</div>
          </OTabItem>
        </OTabs>

        <OTabs
          v-model="activeFramework"
          :expanded="false"
          :animated="false"
          variant="primary"
        >
          <OTabItem>
            <template slot="header">
              <span>HTML/CSS</span>
            </template>

            <div>Content</div>
          </OTabItem>
          <OTabItem>
            <template slot="header">
              <span> Tailwind</span>
            </template>
            <div></div>
          </OTabItem>
          <OTabItem label="Bulma"> <div>Bulma</div> </OTabItem>
          <OTabItem label="Bootstrap 4"> <div>Bootstrap 4</div> </OTabItem>
        </OTabs>
      </section>

      <!-- FileUpload -->
      <section class="p-8 bg-white shadow lg:rounded-lg">
        <h2>File Upload Form</h2>
        <FileUploadForm />
      </section>

      <!-- Upload -->
      <section class="p-8 bg-white shadow lg:rounded-lg">
        <h2>Upload Field</h2>
        <OField>
          <OUpload
            icon-pack="fas"
            icon="upload"
            v-model="dropFiles"
            multiple
            drag-drop
          >
            <section class="text-center">
              <p><OIcon pack="fas" icon="upload"></OIcon></p>
              <p>Drop your files here or click to upload</p>
            </section>
          </OUpload>
        </OField>

        <div class="tags">
          <span v-for="(file, index) in dropFiles" :key="index">
            {{ file.name }}
            <OButton
              icon-left="times"
              size="small"
              native-type="button"
              @click="deleteDropFile(index)"
            >
            </OButton>
          </span>
        </div>
      </section>

      <!-- Buttons -->
      <section class="p-8 bg-white shadow lg:rounded-lg">
        <h2>Buttons</h2>

        <div class="flex w-full mt-4 space-x-4">
          <OButton label="Default" root-class="pippo" />
          <OButton label="Primary" variant="primary" />
          <OButton label="Secondary" variant="secondary" />
          <OButton label="Outlined" outlined />
          <OButton label="Disabled" disabled />
          <OButton label="Add" icon-pack="fas" icon-left="plus" />
          <OButton @click="clickMe">Click Me</OButton>
        </div>

        <div class="flex items-center mt-4 space-x-4">
          <OButton variant="danger" icon-pack="fas" icon-left="trash">
            Delete
          </OButton>

          <OButton variant="danger" icon-pack="fas" icon-right="trash">
            Delete
          </OButton>

          <OButton variant="danger" icon-pack="fas" icon-right="trash" />

          <OButton icon-left="plus" icon-pack="fas" />
        </div>

        <div class="flex items-center mt-4 space-x-4 buttons">
          <OButton label="Default" />
          <OButton label="Primary" variant="primary" />
          <OButton label="Secondary" variant="secondary" />
          <OButton variant="success">Success</OButton>
          <OButton variant="danger">Danger</OButton>
          <OButton variant="warning">Warning</OButton>
          <OButton variant="info">Info</OButton>
          <OButton variant="light">Light</OButton>
          <OButton variant="dark">Dark</OButton>
          <OButton variant="link">Link</OButton>
        </div>

        <div class="flex items-center max-w-sm mt-4 space-x-4 buttons">
          <OButton
            label="Expanded"
            expanded
            size="medium"
            variant="primary"
            rounded
          />
        </div>

        <div class="flex items-center mt-4 space-x-4 buttons">
          <OButton variant="warning">
            <OIcon pack="fas" icon="sync-alt" spin />
            <span class="ml-2">Refresh</span>
          </OButton>

          <button
            type="button"
            class="inline-flex items-center px-4 py-2 text-base font-medium leading-6 text-white transition duration-150 ease-in-out border border-transparent rounded-md cursor-not-allowed bg-rose-600 hover:bg-rose-500 focus:border-rose-700 active:bg-rose-700"
            disabled=""
          >
            <svg
              class="w-5 h-5 mr-3 -ml-1 text-white animate-spin"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              />
              <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              />
            </svg>
            Processing
          </button>

          <o-button variant="danger" icon-pack="fas" icon-right="trash">
            Delete
          </o-button>
        </div>
      </section>

      <!-- Dropdown -->
      <section class="p-8 bg-white shadow lg:rounded-lg">
        <h2>Dropdown</h2>

        <OField
          :message="messages.name"
          :variant="messages.name ? 'danger' : ''"
        >
          <ODropdown aria-role="list" v-model="currentMenu">
            <OButton
              variant="primary"
              slot="trigger"
              slot-scope="{active}"
              :icon-right="active ? 'caret-up' : 'caret-down'"
            >
              <span>{{ currentMenu.text }}</span>
            </OButton>

            <ODropdownItem
              v-for="(menu, index) in menus"
              :key="index"
              :value="menu"
              aria-role="listitem"
            >
              <span>{{ menu.text }}</span>
            </ODropdownItem>
          </ODropdown>
          <OInput
            id="name"
            type="text"
            placeholder="Name"
            v-model="name"
            expanded
          />
        </OField>

        <!--
        <ODropdown aria-role="list">
          <OButton variant="primary" slot="trigger" slot-scope="{active}">
            <span>Click me!</span>
            <OIcon :icon="active ? 'caret-up' : 'caret-down'" />
          </OButton>

          <ODropdownItem aria-role="listitem">Action</ODropdownItem>
          <ODropdownItem aria-role="listitem">Another action</ODropdownItem>
          <ODropdownItem aria-role="listitem">Something else</ODropdownItem>
        </ODropdown>
        -->
      </section>

      <!-- Form -->
      <section class="p-8 bg-white shadow lg:rounded-lg">
        <h2>Form</h2>

        <form class="mt-6 space-y-4">
          <!-- Username -->
          <div>
            <OField label="Username" label-for="username">
              <OInput
                id="username"
                class="block w-full border-gray-300 rounded-md focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                type="text"
                placeholder="Username"
              />
            </OField>
          </div>

          <!-- Password -->
          <div>
            <OField
              label="Password"
              label-for="password"
              variant="danger"
              message="Please choose a password"
            >
              <OInput
                class="block w-full border-gray-300 rounded-md focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                id="password"
                type="password"
                placeholder="Password"
              />
            </OField>
          </div>

          <div class="flex items-center justify-between">
            <OButton label="Sign In" native-type="button" />
            <a
              class="inline-block text-sm font-bold align-baseline text-primary-500 hover:text-primary-800"
              href="#"
            >
              Forgot Password?
            </a>
          </div>
        </form>
      </section>

      <!-- More Examples -->
      <section class="p-8 overflow-hidden bg-white shadow lg:rounded-lg">
        <div class="flex items-center space-x-4">
          <SvgGeorgia class="w-auto text-primary-500 h-72" />
          <SvgFlorida class="w-auto text-primary-500 h-72" />
          <SvgUsa class="w-auto text-primary-500 h-72" />
        </div>
      </section>

      <!-- AutoComplete -->
      <section class="p-8 bg-white shadow lg:rounded-lg">
        <AutoComplete />
      </section>

      <!-- Featured Video -->
      <section
        class="p-8 overflow-hidden bg-white shadow lg:rounded-lg sm:block"
      >
        <FeaturedVideo />
      </section>

      <!-- ActionsPanel -->
      <section>
        <ActionsPanel />
      </section>

      <!-- Announcement Panel -->
      <section
        aria-labelledby="announcements-title"
        class="p-8 overflow-hidden bg-white shadow lg:rounded-lg"
      >
        <a
          href="/Web-Development/"
          class="relative px-2 py-1 font-semibold leading-4 tracking-wide uppercase align-top transition-all transform rounded-md text-primary-700 bg-primary-200 border-primary-700 shadow-tag bg-opacity-10 text-2xs justify-self-start max-h-6 tag box--info"
          title="Web Development"
        >
          Web Development
        </a>

        <AnnouncementsPanel />
      </section>

      <!-- Welcome Panel -->
      <section
        class="hidden mt-8 overflow-hidden bg-white shadow lg:rounded-lg sm:block"
      >
        <WelcomePanel />
      </section>

      <!-- Misc -->
      <section class="hidden p-8 bg-white shadow lg:rounded-lg sm:block">
        <button @click="play">Play a sound</button>
        <Dropdown />
        <AssignSelect />
        <ProfileBio />
        <Divider class="py-8" />
      </section>

      <!-- Settings General Profile -->
      <section class="hidden p-8 bg-white shadow lg:rounded-lg sm:block">
        <SettingsGeneralProfile />
      </section>

      <!-- Settings General Account -->
      <section class="hidden p-8 bg-white shadow lg:rounded-lg sm:block">
        <SettingsGeneralAccount />
      </section>

      <!-- Settings Privacy -->
      <section class="hidden p-8 bg-white shadow lg:rounded-lg sm:block">
        <SettingsPrivacy />
      </section>

      <!-- NavbarStepProgress -->
      <section class="p-8 overflow-hidden bg-white shadow lg:rounded-lg">
        <NavbarStepProgress />
      </section>

      <!-- Projects list (only on smallest breakpoint) -->
      <section
        class="py-4 mt-8 overflow-hidden bg-white shadow lg:rounded-lg sm:hidden"
      >
        <ProjectsList />
      </section>

      <!-- Projects table (small breakpoint and up) -->
      <section class="hidden bg-white shadow lg:rounded-lg sm:block">
        <ProjectsTable />
      </section>

      <!-- StatsPanel -->
      <section>
        <StatsPanels />
      </section>

      <!-- Payment Details -->
      <section>
        <PaymentDetails />
      </section>

      <!-- Plan -->
      <section>
        <Plan />
      </section>

      <!-- Billing History -->
      <section>
        <BillingHistory />
      </section>

      <!-- Applicant Info -->
      <section>
        <ApplicantInfo />
      </section>
    </div>

    <!-- <KeyboardShortcut /> -->
    <OSidebar
      :fullwidth="fullwidth"
      fullheight
      right
      position="fixed"
      :overlay="false"
      background-class=""
      content-class=""
      mobile="fullwidth"
      :open.sync="showSidebar"
    >
      <section
        class="flex flex-col h-full bg-white divide-y divide-gray-200 shadow-xl"
      >
        <div class="flex flex-col flex-1 min-h-0 overflow-y-scroll">
          <header class="px-4 mt-4 sm:px-6">
            <div class="flex items-start justify-between">
              <h2
                id="slide-over-heading"
                class="text-lg font-medium text-gray-900"
              >
                Notifications
              </h2>
              <div class="flex items-center ml-3 h-7">
                <button
                  @click="showNotifications = false"
                  class="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                >
                  <span class="sr-only">Close panel</span>
                  <!-- Heroicon name: x -->
                  <svg
                    class="w-6 h-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </header>

          <article class="relative flex-1 px-4 mt-4 sm:px-6">
            <!-- Replace with your content -->
            <div
              class="h-full border-2 border-gray-200 border-dashed"
              aria-hidden="true"
            />
            <!-- /End replace -->
          </article>
        </div>

        <footer class="flex justify-end flex-shrink-0 px-4 py-4">
          <button
            type="button"
            class="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Cancel
          </button>
          <button
            type="submit"
            class="inline-flex justify-center px-4 py-2 ml-4 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Save
          </button>
        </footer>
      </section>
    </OSidebar>
  </main>
</template>
